import React from "react"
import xss from "xss"

let options = {
  whiteList: {
    div: ["style"],
    b: ["style"],
    p: ["style"],
    br: ["style"],
    bold: ["style"],
    span: ["style"],
  },
}

const myxss = new xss.FilterXSS(options)

export const Description = ({ dirty }) => {
  function createMarkup() {
    return { __html: myxss.process(dirty, options) }
  }

  return dirty ? (
    <div dangerouslySetInnerHTML={createMarkup()}></div>
  ) : (
    <div></div>
  )
}
