import React, { useEffect, useRef, useState } from "react"
import { Link, navigate } from "gatsby"
import { Tabs } from "antd"
import home from "../../../static/icon.png"

import "../../styles/primary.scss"

export const CategoriesNavbar = ({ categories, selectedCategory }) => {
  const ref = useRef()
  const [, setstate] = useState("initialState")
  useEffect(() => {
    if (ref.current) {
      ref.current.goToSlide(3, true)
      setstate("")
    }
  }, [ref.current])
  if (ref.current) {
    ref.current.goToSlide(3, true)
  }

  const onChange = key => {
    let [, index] = key.split("--")
    navigate(`/category${index}`)
  }

  return (
    <div className="navbar__container">
      <Link to="/">
        <div size="small" className="home_button">
          <img src={home} alt="Home" />
        </div>
      </Link>
      <Tabs
        tabBarStyle={{ height: "10vh" }}
        type="card"
        defaultActiveKey={`plain-tab-key--${selectedCategory}`}
        tabPosition="top"
        onChange={onChange}
        animated={{ inkBar: true, tabPane: true }}
        centered
      >
        {categories.map(({ Name, ScreenMenuItems = [] }, tabIndex) => (
          <Tabs.TabPane
            tab={Name}
            key={`plain-tab-key--${tabIndex}`}
          ></Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  )
}
