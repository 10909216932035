import React from "react"
import { Helmet } from "react-helmet"
import { Image, Layout } from "antd"

import defaultImage from "../../static/default.jpg"
import LOGO from "../assets/images/SambaPOS.png"
import { useFirebaseFunctions } from "../customeHooks"
import "../styles/t1/layout.scss"

const { Header, Content, Footer } = Layout

export const CustomLayout = ({
  children,
  isDashboard,
  companyName,
  logo,
  lang = "tr",
}) => {
  useFirebaseFunctions(companyName)

  return (
    <Layout>
      <Helmet
        htmlAttributes={{
          lang,
        }}
      >
        <meta charSet="utf-8" />
        <title>{companyName}</title>
        <meta name="description" content={`QR Menu for ${companyName}`}></meta>
        <meta name="robots" content="index, follow" />
        <meta name="keywords" content="QR Menu, Restaurant, Web Menu, Menu" />
        <meta name="author" content="SambaPOS" />
        <meta name="application-name" content="Samba Menu" />
      </Helmet>
      <Header className={`header_ ${isDashboard ? "dashboard__header" : ""}`}>
        <div className={`header__logo ${isDashboard ? "dashboard__logo" : ""}`}>
          <Image
            alt="logo"
            src={logo || defaultImage}
            className="logo__image"
            preview={false}
          />
          <div className="header__company">{companyName}</div>
        </div>
      </Header>
      <Content className={`content ${isDashboard ? "dashboard" : ""}`}>
        {children}
      </Content>
      <Footer className="footer">
        <div className="footer__copyright">
          Powered by
          <img src={LOGO} alt="" />
          <a
            href="https://sambapos.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            SambaPOS
          </a>
        </div>
      </Footer>
    </Layout>
  )
}
