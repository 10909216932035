import { useEffect } from "react"
import "firebase/analytics"

import firebase from "gatsby-plugin-firebase"

export function useFirebaseFunctions(
  companyName = "",
  logEvent = "samba_go_menu_rendered"
) {
  useEffect(frb => {
    firebase
      .analytics()
      .logEvent("samba_go_menu_rendered", { company_name: companyName })
    console.log("analytics event")
  }, [])
  return firebase
}
